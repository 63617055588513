<script setup lang="ts">
import type {PropType} from "vue";
import type {WpMedia, Settings} from "~/integration/wordpress/types";
import {useWp} from "~/composables/Redmonkey";
import Logo from "~/components/Redmonkey/Header/Logo.vue";
import MenuItem from "~/components/Redmonkey/Header/MenuItem.vue";
const FooterForm = defineAsyncComponent(() => import("~/components/Redmonkey/FrmForm/FrmFormWrapper.vue"))

const {
  getSettingsValue,
  extractShortcodeParam,
  formatPhoneToTel
} = useWp();

const props = defineProps({
  menu: {
    type: Object
  },
  logo: {
    type: Object as PropType<WpMedia>
  },
  settings: {
    type: Object as PropType<Settings | undefined>
  }
})

const getMenu1 = () => {
  return props.menu?.footer_1?.menuItems?.nodes;
}

const getMenu2 = () => {
  return props.menu?.footer_2?.menuItems?.nodes;
}

const getMenu2Name = () => {
  return props.menu?.footer_2?.name;
}

const getFormId = () => {
  return extractShortcodeParam(getSettingsValue(props.settings, 'footerSubscribeShortcode'), 'id');
}

</script>
<template>
  <footer>
    <div class="container">
      <div class="footer-columns flex wrap justify">
        <div class="footer-column footer-column-1">
          <Logo :logo="logo"/>
          <div class="menu-after-logo" v-if="getMenu1()">
            <template v-for="item in getMenu1()" :key="item.label">
              <MenuItem :marker="false" :item="item"/>
            </template>
          </div>
          <div class="contact-info">
            <ul>
              <li class="phone"><a v-if="getSettingsValue(settings, 'phoneNumber')" :href="formatPhoneToTel(getSettingsValue(settings, 'phoneNumber'))">{{ getSettingsValue(settings, 'phoneNumber') }}</a></li>
              <li class="email"><a v-if="getSettingsValue(settings, 'email')"
                     :href="`mailto:${getSettingsValue(settings, 'email')}`">{{ getSettingsValue(settings, 'email') }}</a></li>
            </ul>
          </div>
          <div class="social">
            <ul>
              <li class="twitter" v-if="getSettingsValue(settings, 'socialTwitter')">
                <a target="_blank" :href="getSettingsValue(settings, 'socialTwitter')"></a>
              </li>
              <li class="tiktok" v-if="getSettingsValue(settings, 'socialTiktok')">
                <a target="_blank" :href="getSettingsValue(settings, 'socialTiktok')"></a>
              </li>
              <li class="instagram" v-if="getSettingsValue(settings, 'socialInstagram')">
                <a target="_blank" :href="getSettingsValue(settings, 'socialInstagram')"></a>
              </li>
              <li class="facebook" v-if="getSettingsValue(settings, 'socialFacebook')">
                <a target="_blank" :href="getSettingsValue(settings, 'socialFacebook')"></a>
              </li>
              <li class="linkedin" v-if="getSettingsValue(settings, 'socialLinkedin')">
                <a target="_blank" :href="getSettingsValue(settings, 'socialLinkedin')"></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer-column footer-column-2" v-if="getMenu2()">
          <h5 v-if="getMenu2Name()">{{ getMenu2Name() }}</h5>
          <template v-for="item in getMenu2()" :key="item.label">
            <MenuItem :marker="false" :item="item"/>
          </template>
        </div>
        <div class="footer-column footer-column-3">
          <h5 v-if="getSettingsValue(settings, 'addressesTitle')">{{
              getSettingsValue(settings, 'addressesTitle')
            }}</h5>
          <template v-if="getSettingsValue(settings, 'addresses')"
                    v-for="item in getSettingsValue(settings, 'addresses')" :key="item.city">
            <div class="address-item">
              <h6 class="title">{{ item.city }}</h6>
              <p class="address">{{ item.address }}</p>
            </div>
          </template>
        </div>
        <div class="footer-column footer-column-4" v-if="getSettingsValue(settings, 'footerSubscribeShortcode')">
          <p class="sub-caption" v-if="getSettingsValue(settings, 'footerCaptionSubscribe')"
             v-html="getSettingsValue(settings, 'footerCaptionSubscribe')"></p>
          <div class="footer-form">
            <FooterForm :form-id="getFormId()" :key="`FORM_${getFormId}`"></FooterForm>
          </div>
        </div>
      </div>
      <div class="cop" v-if="getSettingsValue(settings, 'cop')">
        {{ getSettingsValue(settings, 'cop').replace('{year}', new Date().getFullYear()) }}
      </div>
    </div>
  </footer>
</template>